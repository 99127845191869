import AdminPaymentFormDetail from "../../../components/admin/admin-payment-form-detail";
import Layout from "../../../components/layout";

const AdminPaymentFormDetailPage = () => {
    return (
        <Layout>
            <AdminPaymentFormDetail/>
        </Layout>
    );
};

export default AdminPaymentFormDetailPage;