import styled from "styled-components";
import media from "../../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
`

export const Inner = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 100px 0 116px;

    ${media.tablet`
        box-sizing: border-box;
        padding: 100px 0px 116px;
    `};

    ${media.mobile`
        padding: 40px 0px;
    `};
`

export const ItemBx = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 20px;
    box-sizing: border-box;

    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
        padding: 0 20px;
    `};

    ${media.mobile`
        grid-template-columns: repeat(1, 1fr);    
    `};
`

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    a{
        display: block;
        height: 100%;
    }
`

export const ItemWrap = styled(motion.div)`
    position: relative;
    line-height: 0;
    font-size: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
        img {
            transform: scale(1.15);
        }
    }

    img {
        transition: transform 0.2s;
    }

`

export const ItemPrice = styled.div`
    position: absolute;
    top: 0;
    right: 8px;
    padding: 4px 12px;
    background-color: #C6151B;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    z-index: 10;

    span {
        font-family: Poppins, serif;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #ffffff;
    }

    ${media.mobile`
        right: 16px;
        span{
            font-size: 20px;
            line-height: 30px;
        }
    `};
`

export const ItemImageBx = styled.div`
    overflow: hidden;
    height: 256px;
    flex-shrink: 0;
    position: relative;

    img {
        width: 100%;
        height: 256px;
        object-fit: cover;
        object-position: center;
    }
`

export const Message = styled.div`
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 16px;
    border-radius: 10px;
`

export const ItemInfo = styled.div`
    position: relative;
    padding: 26px 20px 40px;
    height: 100%;

    dl {
        position: relative;
        z-index: 10;
        left: 0;
        top: 0;

        dt {
            font-weight: 700;
            font-size: 26px;
            line-height: 42px;
            color: #ffffff;
            margin-bottom: 12px;
        }

        dd {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: keep-all;
            height: 44px;
        }

        ${media.mobile`
            dl{
                dd{
                    height: 36px;
                }
            }
        `};
    }

    ${media.mobile`
        padding: 20px 20px 29px;
        dl{
            dt{
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 8px;
            }
            dd{
                font-size: 14px;
                line-height: 19px;
                white-space: initial;
                word-break: keep-all;
            }
        }
    `};
`

export const ItemInfoBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`

export const getFilter = function ($type: string) {
    if ($type === 'RED') return 'linear-gradient(180deg, rgba(198, 21, 27, 0.3) 0%, #C6151B 100%)';
    else if ($type === 'PURPLE') return 'linear-gradient(180deg, rgba(104, 21, 198, 0.3) 0%, #6815C6 100%)';

    return 'linear-gradient(180deg, rgba(21, 115, 198, 0.3) 0%, #1573C6 100%)';
}

export const ItemInfoBgFilter = styled.div<{ $type: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${p => getFilter(p.$type)};
    backdrop-filter: blur(10px);
    z-index: 1;
`
