import styled from "styled-components";

export const CategoryBx = styled.div`
    h3 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }
`

export const TableBx = styled.table`
    width: 100%;
    border-collapse: collapse;
    
    th, td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: left;

        &:nth-child(1) {
            width: 80px;
        }

        &:nth-child(2) {
            width: auto;
        }

        &:nth-child(3) {
            width: 30px;
        }

        &:nth-child(4) {
            width: 80px;
        }

        &:nth-child(5) {
            width: 80px;
        }

        &:nth-child(6) {
            width: 80px;
            text-align: center;
        }
    }

    th {
        background: #f8f8f8;
        font-size: 16px;
        font-weight: 700;
        color: #383838;
        text-align: center;
    }
    
    td{
        font-size: 14px;
        font-weight: 600;
        color: #383838;
    }

    @media screen and (max-width: 768px) {
        display: block;
        overflow-x: auto;
    }
`