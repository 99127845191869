import styled from "styled-components";

const Inner = styled.div`
    span{
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        background-color: #C6151B;
        border-radius: 5px;
        padding: 4px 12px;
        color: #ffffff;
    }
`

const PriceTag = ({ price }: { price: string | number }) => {
    return(
        <Inner>
            <span>{price}</span>
        </Inner>
    )
}

export default PriceTag;