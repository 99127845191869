import styled from "styled-components";
import {motion} from "framer-motion";

export const PopupItem = styled(motion.li)`
    width: 400px;
    background-color: #000000;
    position: relative;

    a {
        display: block;
        font-size: 0;
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            width: 100%;
            height: 450px;
            object-fit: cover;
            opacity: 0.4;
            transition: transform 0.2s;
        }
    }
`

export const InfoBx = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    dl {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 20px;

        dt {
            font-weight: 700;
            font-size: 24px;
            color: #ffffff;
        }

        dd {
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            color: #ffffff;
            white-space: pre-wrap;
        }
    }
`

export const CloseBx = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    background-color: #ffffff;

    button {
        font-size: 14px;
        padding: 15px 10px;
        width: 100%;

        &:first-child {
            border-right: 1px solid #000000;
        }
    }
`