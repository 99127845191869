import Calculator from "./calculator";
import {createContext, useCallback, useContext, useEffect, useState} from "react";
import * as S from "./styles";
import {getComma} from "../../../../lib/comma";
import {CalculatorCompleteDataType, CalculatorDefaultType} from "../../../../types/calculator";
import TitleBx from "../../../common/title-bx";

function getSum(list?: any): number {
    let sum = 0;
    list?.forEach((v: any) => {
        const price = v.price as number;
        if (!isNaN(price)) {
            sum += +v.price;
        }
    });

    return sum;
}

export const CalculatorContext = createContext<{
    init: () => void;
    setSchedule: <T, K extends keyof CalculatorCompleteDataType>(info: T, key: K) => void,
    complete: () => void,
    data: CalculatorCompleteDataType
}>({
    init: () => void {},
    setSchedule: function <T, K extends keyof CalculatorCompleteDataType>(info: T, key: K) {
    },
    complete: () => {
    },
    data: {}
});

interface Props {
    onComplete: (data: any) => void;
}


const CalculatorPopup = ({onComplete}: Props) => {
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<CalculatorCompleteDataType>({});

    const init = useCallback(() => {
        console.log(' [[[[[[[[[[[[[[[[[[[ 초기화 ]]]]]]]]]]]]]]]]]]]');
        setData({});
    }, []);

    const setSchedule = useCallback(function <T, K extends keyof CalculatorCompleteDataType>(info: T, key: K) {
        setData({
            ...data,
            [key]: info,
        });

    }, [data]);

    useEffect(() => {
        let sum = 0;

        console.log("------------------------- Data -------------------------");
        console.log(data);

        if (data.funDivingList) {
            const price = data.funDivingList.process.price as number;
            sum += +price;
        }

        sum += getSum(data.funDivingList?.processList);

        if (data.technicalList) {
            const price = data.technicalList.process.price as number;
            sum += +price;
        }

        sum += getSum(data.technicalList?.processList);
        sum += getSum(data.technicalList?.technicalOptions);
        sum += getSum(data.more);

        if (data.gender) {
            const allMember = (data.gender.man.member + data.gender.woman.member) || 1;
            sum = sum * allMember;
        }

        data.rental?.man.forEach((list: CalculatorDefaultType) => {
            sum += getSum(list);
        });

        data.rental?.woman.forEach((list: CalculatorDefaultType) => {
            sum += getSum(list);
        });

        setTotal(sum);
    }, [data]);

    useEffect(() => {
        setSchedule({totalPrice: total}, 'total');
    }, [total]);

    const complete = useCallback(async () => {
        onComplete(data);
    }, [data]);

    return (
        <CalculatorContext.Provider value={{init, setSchedule, complete, data}}>
            <S.Wrap>
                <TitleBx title={'계산기'} description={'여행 계획을 직접 짜보세요.'}/>
                <S.TotalBx>
                    <span>
                        가격 합계 : ${getComma(total)}
                    </span>
                </S.TotalBx>
                <Calculator/>
            </S.Wrap>
        </CalculatorContext.Provider>
    )
}

export const useCalculatorContext = () => useContext(CalculatorContext);

export default CalculatorPopup;