import {useCallback} from "react";
import styled from "styled-components";
import * as S from "../styles";

const Wrap = styled.div`
`

const Inner = styled.div`
`

interface Props {
    onClickPayment: (pg: string) => void;
}

const PaymentBx = ({ onClickPayment }: Props) => {
    return (
        <Wrap>
            <Inner>
                <S.ContentBx>
                    <h2>결제 수단</h2>
                    <S.PaymentListBx>
                        <button onClick={() => onClickPayment('kakaopay')}>
                            <img src="/image/btn_pay_kakao.png" alt=""/>
                        </button>
                        <button onClick={() => onClickPayment('tosspay')}>
                            <img src="/image/btn_pay_toss.png" alt=""/>
                        </button>
                        <button onClick={() => onClickPayment('nice_v2')}>
                            다른 결제 수단 선택
                        </button>
                    </S.PaymentListBx>
                </S.ContentBx>

            </Inner>
        </Wrap>
    )
}

export default PaymentBx;