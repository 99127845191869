import {usePaymentList} from "../../../api/usePayment";
import Board from "../../common/board";
interface Props {
    userId: number;
}

const MyPayment = ({userId}: Props) => {
    const list = usePaymentList(userId);

    if (!list) return null;

    return (
        <div>
            <Board list={list} link={'/payment-form-detail/'}></Board>
        </div>
    )
}

export default MyPayment;