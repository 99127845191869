import styled from "styled-components";

export const Inner = styled.div`
    width: 100%;
    margin-bottom: 30px;
    overflow-x: auto;
`

export const TitleBx = styled.div`
    font-size: 20px;
    font-weight: 700;
    border: 3px solid #000000;
    display: inline-block;
    padding: 10px 30px;
    border-bottom: none;
    box-sizing: border-box;

    @media screen and (max-width: 480px) {
        font-size: 16px;
        padding: 8px 20px;
    }
`

export const TableBx = styled.table`
    width: 100%;
    /* min-width: 600px; */
    border: 3px solid #000000;
    border-collapse: collapse;


    tr {
        width: 100%;

        th {
            border: 3px solid #000000;
            font-weight: 500;
            padding: 10px 0;

            &:nth-child(1) {
                width: 100px;
            }

            &:nth-child(2) {
                width: auto;
            }

            &:nth-child(3) {
                width: 60px;
            }

            &:nth-child(4) {
                width: 60px;
            }

            &:nth-child(5) {
                width: 120px;
            }

            &:nth-child(6) {
                width: 70px;
            }
        }

        td {
            border: 1px solid #000000;

            input {
                height: 30px;
                width: 100%;
                box-sizing: border-box;
                border: none;
                padding: 0 10px;
                background-color: transparent;
            }

            &:nth-child(5) {
                gap: 10px;
                position: relative;

                span {
                    flex-shrink: 0;
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    transform: translateY(-50%);
                }

                input {
                    padding-left: 20px;
                }
            }

            &:nth-child(6) {
                text-align: center;
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
`

export const ListTotalBx = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow-x: auto;
`

export const ListTotal = styled.div`
    position: relative;
    border: 3px solid #000000;
    border-top: none;
    width: 120px;

    span {
        flex-shrink: 0;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
    }

    input {
        border: none;
        width: 100%;
        box-sizing: border-box;
        height: 30px;
        background-color: yellow;
        padding: 0 10px 0 20px;
    }
`

export const BtnDelete = styled.button`
    background-color: red;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 0;
    width: 80%;
    border-radius: 5px;
    overflow: hidden;
`