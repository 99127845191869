import {PaymentFormType} from "../../../types/admin/payment-from";

export const DivingData: PaymentFormType = {
    type: 'diving',
    unit: '$',
    title: 'A. 다이빙/숙박',
}

export const EquipmentData: PaymentFormType = {
    type: 'equipment',
    unit: '$',
    title: 'B. 장비/보트/탱크'
}

export const MealData: PaymentFormType = {
    type: 'meal',
    unit: '$',
    title: 'C. 식사',
}

export const AddData: PaymentFormType = {
    type: 'add',
    unit: '$',
    title: '추가사항(음료 등)',
}
