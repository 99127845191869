import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
`

export const Inner = styled.div`
`

export const ContentBx = styled.div`
    h2 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    ${media.mobile`
        h2{
            font-size: 20px;
            margin-bottom: 10px;
        }
    `};
`

export const ListBx = styled.div`
    border-top: 2px solid #cecece;

    > dl {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        border-bottom: 1px solid #e4e4e4;
        padding: 0;
        margin: 0;

        > dt {
            flex-shrink: 0;
            width: 150px;
            text-align: right;
            border-right: 1px solid #e4e4e4;
            background: #f4f4f4;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 12px 10px 12px 0;
            box-sizing: border-box;

            span {
                font-size: 16px;
                font-weight: 700;
                color: #333;
                position: relative;
            }
        }

        > dd {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            color: #333;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            padding: 10px 16px;
            box-sizing: border-box;
            width: 100%;

            input[type='text'], textarea {
                color: #333;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid #e4e4e4;
                padding: 5px 10px;

                &::placeholder {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.35);
                }
            }

            textarea {
                color: #333;
                font-size: 16px;
                font-weight: 500;
                border: 1px solid #e4e4e4;
                padding: 10px 10px;
                max-width: 500px;
                width: 100%;
                height: 150px;
            }
        }
    }

    ${media.mobile`
        > dl{
            > dt{
                width: 85px;
                span{
                    font-size: 14px;
                }
            }
            
            > dd{
                font-size: 14px;
                padding: 6px 10px;
                padding-right: 0;
                
                input{
                    width: 100%;
                }
                
                textarea{
                    width: 100%;
                }
            }
        }
    `};
`

export const InfoBx = styled.ul`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #FFF2F3;
        min-width: 85px;
        min-height: 70px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        font-weight: 700;
        font-size: 15px;
        color: #C6151B;
        text-align: center;
        white-space: pre-wrap;

        span {
            margin-top: 2px;
            display: block;
            font-size: 13px;
        }
    }

    ${media.mobile`
        display: grid;
        grid-template-columns: 1fr 1fr;
        li{
            
        }
    `};
`

export const PaymentListBx = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    button {
        width: 200px;
        height: 66px;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        border: 1px solid #e4e4e4;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 700;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }

    ${media.mobile`
        margin-top: 30px;
        flex-direction: column;
        justify-content: center;
    `};
`

export const PriceBx = styled.div`
    display: inline-block;
    font-size: 17px;
    font-weight: 700;
    background-color: #C6151B;
    border-radius: 5px;
    padding: 8px 15px;
    color: #ffffff;
`

export const CheckContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const CheckBx = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    input {
        display: none;

        &:checked + label {
            i {
                border: 1px solid #C6151B;
                background-color: #C6151B;

                &::before {
                    background-color: #ffffff;
                }
            }
        }
    }

    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        i {
            border: 1px solid #aaaaaa;
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;

            &::before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: #e4e4e4;
            }
        }
    }

    button {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 3px;

        &:hover {
            img {
                transform: translateX(5px);
            }
        }

        img {
            width: 14px;
            position: relative;
            top: -1px;
            transition: transform 0.2s;
        }
    }

`

export const ExchangeInfoBx = styled.div`
    display: flex;
    flex-direction: column;
`

export const PriceContainer = styled.div`
    dl {
        display: flex;
        gap: 5px;
        flex-direction: column;

        dt {
            font-size: 18px;
            font-weight: 700;
        }

        dd {

        }
    }
`
