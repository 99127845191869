import Layout from "../../layout";
import {Outlet, useNavigate} from "react-router-dom";
import {Link, useLocation} from "react-router-dom";
import * as S from "./styles";
import {AdminMenuBx} from "./styles";
import {useAuthContext} from "../../auth-provider";
import {useEffect, useState} from "react";

export const AdminLayout = () => {
    const {user, login, isLoading} = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [depthList] = useState<string[]>(
        location.pathname.split("/")
    );

    const [depth, setDepth] = useState<string>(`/${depthList[1]}/${depthList[2]}`);

    const menuList = [
        // {
        //     title: '어드민',
        //     path: '/admin'
        // },
        {
            title: '정산서 생성',
            path: '/admin/payment-form'
        },
        {
            title: '정산서 목록',
            path: '/admin/payment-form-list'
        },
    ];

    useEffect(() => {
        if (!isLoading) {
            if (!user) {
                login();
            } else if (user.admin !== 1) {
                navigate("/");
            }
        }
    }, [user, isLoading]);

    useEffect(() => {
        const url = location.pathname.split("/");

        setDepth(`/${url[1]}/${url[2]}`);
    }, [location]);

    if (!user || user.admin !== 1) return null;

    return (
        <Layout>
            <S.Wrap>
                <S.Inner>
                    <S.TitleBx>
                        <h1>관리자 페이지</h1>
                    </S.TitleBx>

                    <AdminMenuBx>
                        <ul>
                            {
                                menuList.map((menu) => (
                                    <S.AdminMenu key={menu.path}
                                                 $isActive={menu.path === depth}>
                                        <Link
                                            to={menu.path}
                                            className={location.pathname === menu.path ? 'active' : ''}
                                        >
                                            {menu.title}
                                        </Link>
                                    </S.AdminMenu>
                                ))
                            }
                        </ul>
                    </AdminMenuBx>

                    <S.LayoutBx>
                        <Outlet/>
                    </S.LayoutBx>
                </S.Inner>
            </S.Wrap>
        </Layout>
    )
}

export default AdminLayout;