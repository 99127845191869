import styled from "styled-components";

export const Wrap = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
`

export const Inner = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 50px 0 0 50px;

    ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }
`

