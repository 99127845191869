import AdminPaymentFormAdd from "../../../components/admin/admin-payment-form-add";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {ExchangeType, PaymentFormListData, PaymentInputListData} from "../../../types/admin/payment-from";
import {useAddPayment, useExchange} from "../../../api/exchange";
import {useParams} from "react-router-dom";
import {exchangeUsdToKwa, exchangeUsdToPhp} from "../../../lib/exchange";

const getList = (list: PaymentInputListData) => {
    return !(list.add.length === 0 && list.diving.length === 0 && list.equipment.length === 0 && list.meal.length === 0);
}

interface PaymentFormType {
    totalKWA: number;
    totalUSD: number;
    setPaymentForm: (type: keyof PaymentInputListData, data: PaymentFormListData[]) => void;
    paymentList: PaymentInputListData;
    exchange: ExchangeType;
    onClickCreate: () => void;
    teamName: string;
    setTeamName: (name: string) => void;
}

export const PaymentFormConText = createContext<PaymentFormType | null>(null);

const PaymentFormPrivider = ({children}: { children: ReactNode }) => {
    const {id} = useParams();
    const exchange = useExchange();
    const {mutate} = useAddPayment(id);

    const [totalKWA, setKWA] = useState<number>(0);
    const [totalUSD, setUSD] = useState<number>(0);
    const [teamName, setTeamName] = useState<string>('');

    const [paymentList, setPaymentList] = useState<PaymentInputListData>({
        diving: [],
        equipment: [],
        meal: [],
        add: [],
    });

    const setPaymentForm = (type: keyof PaymentInputListData, data: PaymentFormListData[]) => {
        setPaymentList((prev: PaymentInputListData) => ({
            ...prev,
            [type]: data
        }));
    }

    useEffect(() => {
        if (exchange) {
            let sum = 0;
            for (const [key, list] of Object.entries(paymentList)) {
                list.forEach((v: PaymentFormListData) => {
                    if (v.isPayment !== '1') {
                        const t = +v.qty * +v.price;
                        sum += t;
                    }
                })
            }

            setUSD(sum);
            setKWA(exchangeUsdToKwa(sum, exchange.usd));
        }
    }, [paymentList, exchange]);

    const onClickCreate = async () => {
        if (id) {
            const formData: PaymentInputListData = {
                diving: [],
                equipment: [],
                meal: [],
                add: [],
            };

            Object.entries(paymentList).forEach(([key, list]) => {
                list.forEach((item: PaymentFormListData) => {
                    if (+item.unit > 0) {
                        if (!item.isOld) {
                            formData[key as keyof PaymentInputListData].push(item);
                        }
                    }
                });
            });

            if (!getList(formData)) {
                alert("1개 이상의 리스트를 작성해주세요.");
                return;
            }

            if (teamName === '') {
                alert('팀 이름을 작성해주세요.');
                return;
            }

            mutate({params: formData, id});
            alert("항목이 추가 되었습니다.");
        }
    }

    return (
        <PaymentFormConText.Provider
            value={{
                totalKWA,
                totalUSD,
                setPaymentForm,
                paymentList,
                exchange,
                onClickCreate,
                teamName,
                setTeamName
            }}>
            {children}
        </PaymentFormConText.Provider>
    )
}

export const usePaymentFormContext = (): PaymentFormType => {
    const context = useContext(PaymentFormConText);
    if (!context) {
        throw new Error("usePaymentFormContext Error");
    }
    return context;
}

export const AdminPaymentFormAddPage = () => {
    return (
        <PaymentFormPrivider>
            <AdminPaymentFormAdd/>
        </PaymentFormPrivider>
    )
}

export default AdminPaymentFormAddPage;