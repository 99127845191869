import moment from "moment/moment";
import {useExchange} from "../../../api/exchange";
import styled from "styled-components";

const ExchangeInfoBx = styled.div`
    > dl {
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin-bottom: 20px;
        border: 1px solid #e4e4e4;
        box-sizing: border-box;
        padding: 5px 10px;
        border-radius: 5px;
        overflow: hidden;

        > dt {
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            line-height: 16px;
        }

        > dd {
            font-size: 14px;
            font-weight: 700;
            color: #666666;
        }
    }
`


const ExchangeInfo = () => {
    const exchange = useExchange();
    if (!exchange) return null;

    return (
        <ExchangeInfoBx>
            <dl>
                <dt>
                    환율 정보( 1$ 기준 )<br/>
                    ({moment(exchange.updatedAt).format('YYYY/MM/DD')})
                </dt>
                <dd>
                    KWA(₩) : {exchange.usd}원
                </dd>
            </dl>
        </ExchangeInfoBx>
    )
}

export default ExchangeInfo;