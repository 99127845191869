import * as S from "./styles";
import {MainPopupType} from "../../../../types/main";
import {useCallback, useState} from "react";
import BtnDefault from "../../../common/btn-default";
import {usePopupModify} from "../../../../api/popup";

interface Props {
    idx: number;
    data: MainPopupType;
}

const MyPopupItem = ({idx, data}: Props) => {
    const { mutate } = usePopupModify();
    const [popupData, setPopupData] = useState<MainPopupType>({
        id: data.id,
        title: data.title,
        description: data.description,
        hidden: data.hidden,
        link: data.link
    });

    const onChange = (value: string | boolean, key: string) => {
        setPopupData({
            ...popupData,
            [key]: value
        });
    }

    const onClick = useCallback(() => {
        mutate(popupData);
    }, [popupData]);

    return (
        <S.Wrap>
            <S.Inner>
                <h2>[ 팝업 {idx + 1} ]</h2>

                <S.InputText>
                    <label htmlFor="">제목</label>
                    <input
                        type="text"
                        defaultValue={popupData.title}
                        onChange={(e) => onChange(e.target.value, "title")}
                    />
                </S.InputText>
                <S.InputText>
                    <label htmlFor="">내용</label>
                    <textarea
                        defaultValue={popupData.description}
                        onChange={(e) => onChange(e.target.value, "description")}
                    />
                </S.InputText>
                <S.InputText>
                    <label htmlFor="">링크</label>
                    <input
                        type="text"
                        defaultValue={popupData.link}
                        onChange={(e) => onChange(e.target.value, "link")}
                    />
                </S.InputText>
                <S.InputCheck>
                    <label htmlFor="">활성화</label>
                    <input
                        type="checkbox"
                        defaultChecked={!popupData.hidden}
                        onChange={(e) => onChange(e.target.checked, "hidden")}
                    />
                </S.InputCheck>

                <BtnDefault title={'저장'} onClick={onClick}></BtnDefault>
            </S.Inner>
        </S.Wrap>
    )
}

export default MyPopupItem;