import {AdvancedData, AdvancedDiverContentData, AdvancedPackageData} from "./data";

import DiverVisual from "../../common/diver-visual";
import DiverLesson from "../../common/diver-lesson";
import DiverInfo from "../../common/diver-info";
import AdvencedPackage from "../../common/advanced-package";
import BtnPayment from "../../common/btn-payment";

const OpenwaterAdvanced2 = () => {
    return (
        <div>
            <DiverVisual imgSrc={'/image/img_diver_visual.png'}/>
            <DiverLesson info={AdvancedData}/>
            <DiverInfo info={AdvancedDiverContentData}/>
            <BtnPayment />
            {/*<AdvencedPackage info={AdvancedPackageData}/>*/}
        </div>
    )
}

export default OpenwaterAdvanced2;