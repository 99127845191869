import * as S from "./styles";


interface Props {
    category: string;
    title: string;
    detail: any;
}

const PaymentDetailTable = ({category, title, detail}: Props) => {
    return (
        <>
            <S.CategoryBx>
                <h3>{title}</h3>
                <S.TableBx>
                    <thead>
                    <tr>
                        <th>날짜</th>
                        <th>항목</th>
                        <th>수량</th>
                        <th>가격</th>
                        <th>합계</th>
                        <th>입금 여부</th>
                    </tr>
                    </thead>
                    <tbody>
                    {detail.map((item: any, index: number) => {
                        const unit = '$';
                        return (
                            <tr
                                key={`payment-detail-${index}`}
                                style={{'backgroundColor': item.isPayment === "1" ? 'rgba(255, 255, 0, 0.5)' : 'transparent'}}
                            >
                                <td>{item.date}</td>
                                <td>{item.articles}</td>
                                <td>{item.qty}</td>
                                <td>{unit} {item.price}</td>
                                <td>{unit} {item.unit}</td>
                                <td>
                                    {item.isPayment === "1" ? '[입금 완료]' : '입금 전'}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </S.TableBx>
            </S.CategoryBx>
        </>
    )
}

export default PaymentDetailTable;