import styled from "styled-components";
import media from "../../../lib/media";

export const Wrap = styled.div`
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
`

export const Inner = styled.div`
`

export const TitleBx = styled.div`
    margin-bottom: 30px;

    h2 {
        font-size: 24px;
        font-weight: 500;
    }
`

export const ListBx = styled.div`
    gap: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    ${media.tablet`
        grid-template-columns: repeat(2, 1fr);
    `};

    ${media.mobile`
        grid-template-columns: repeat(1, 1fr);
    `};
`

export const Empty = styled.div`
    width: 100%;
    padding: 100px 0;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`