import * as S from "./styles";
import {useAuthContext} from "../auth-provider";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ContentType} from "../../types/my";
import UserInfo from "./UserInfo";
import MyMenus from "./MyMenus";
import MyContent from "./MyContent";
import MyContents from "./MyContents";
import {TitlesData} from "./data";

const My = () => {
    const {user, login} = useAuthContext();
    const {contentTypeName} = useParams<{ contentTypeName: keyof ContentType }>();
    const navigate = useNavigate();
    const [contentType, setContentType] = useState<keyof ContentType>();

    useEffect(() => {
        if (contentTypeName) {
            setContentType(contentTypeName);
        }
    }, [contentTypeName]);

    useEffect(() => {
        if (!user) login();
    }, [user]);

    const onClick = useCallback((type: keyof ContentType) => {
        navigate(`/my/${type}`);
    }, []);

    if (!user || !contentType) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.MenuBx>
                    <UserInfo user={user} onClick={onClick}></UserInfo>
                    <S.MenuList>
                        <ul>
                            <MyMenus onClick={onClick} contentType={contentType} menuType={"notice"}/>
                            <MyMenus onClick={onClick} contentType={contentType} menuType={"payment"}/>
                            {
                                user.admin === 1 && <>
                                    <MyMenus onClick={onClick} contentType={contentType} menuType={"news"}/>
                                    <MyMenus onClick={onClick} contentType={contentType} menuType={"story"}/>
                                    <MyMenus onClick={onClick} contentType={contentType} menuType={"popup"}/>
                                </>
                            }
                        </ul>
                    </S.MenuList>
                </S.MenuBx>

                <S.ContentBx>
                    <S.ContentTitle>
                        {TitlesData[contentType]}
                    </S.ContentTitle>
                    {
                        contentType === "my" ? <MyContent/> : <MyContents contentType={contentType}/>
                    }
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default My;