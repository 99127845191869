import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAxios, postAxios} from "./apiLink";
import {ErrorMessage} from "../lib/error";

export const useUser = () => {
    const {data: user, isLoading} = useQuery({
        queryFn: () => getAxios('/user/info'),
        queryKey: ['user-info'],
        retry: false,
    });

    return {
        user: user?.data,
        isLoading
    }
}

export const useLogout = (callback: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => postAxios('/user/logout', {}),
        onSuccess: () => {
            queryClient.removeQueries({
                queryKey: ['user-info']
            });

            alert("로그아웃 되었습니다.");
            callback();
        },
        onError: (error) => {
            const message = ErrorMessage(error);
            console.log(message);
        }
    })
}