import * as S from "./styles";
import PackageList from "../package-list";
import CalendarBx from "../calendar-bx";
import {useEffect, useState} from "react";
import Personnel from "../personnel";
import RentalComponent from "../rental-component";
import BtnDefault from "../../../../../common/btn-default";
import {useCalculatorContext} from "../../index";
import {GetProcess} from "../../../../../../api/calculator";
import {CalculatorListType} from "../../../../../../types/calculator";

const TechnicalBx = () => {
    const [technicalData, setTechnicalData] = useState<CalculatorListType[]>();
    const getData = async () => {
        const result = await GetProcess(1);
        setTechnicalData(result.list);
    }

    useEffect(() => {
        getData();
    }, []);

    const {complete, data} = useCalculatorContext();

    const [man, setMan] = useState<number>(0);
    const [woman, setWoman] = useState<number>(0);

    if (!technicalData) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx $isView={true}>
                    <PackageList
                        key={'technical-list'}
                        calculatorList={technicalData}
                        packageName={'technicalList'}
                    />
                </S.ContentBx>

                <S.ContentBx $isView={!!data.technicalList}>
                    <h3>2. 날짜 선택하기</h3>
                    {
                        data.technicalList && <CalendarBx type={'TECHNICAL'}/>
                    }
                </S.ContentBx>

                <S.ContentBx $isView={!!data.schedule}>
                    <h3>3. 참가 인원</h3>
                    {
                        data.schedule && <Personnel
                            setMan={setMan}
                            setWoman={setWoman}
                        />

                    }
                </S.ContentBx>

                <S.ContentBx $isView={(man > 0 || woman > 0)}>
                    <h3>4. 렌탈 선택</h3>
                    {
                        (man > 0 || woman > 0) && <RentalComponent man={man} woman={woman}/>
                    }
                </S.ContentBx>

                {/*<S.ContentBx $isView={(man > 0 || woman > 0)}>*/}
                {/*    <h3>5. 추가 입력</h3>*/}
                {/*    {*/}
                {/*        (man > 0 || woman > 0) && <MessageComponent/>*/}
                {/*    }*/}
                {/*</S.ContentBx>*/}

                <S.ContentBx $isView={(man > 0 || woman > 0)}>
                    <BtnDefault
                        title={'최종 완료'}
                        onClick={complete}
                        disabled={!(man > 0 || woman > 0)}
                    />
                </S.ContentBx>

            </S.Inner>
        </S.Wrap>
    )
}

export default TechnicalBx;