import * as S from "../styles";
import {getComma} from "../../../../lib/comma";
import {useExchange} from "../../../../api/exchange";
import {exchangeUsdToKwa} from "../../../../lib/exchange";
import ExchangeInfo from "../../../common/exchange-info";
import {useEffect} from "react";

interface Props {
    technicalData: any;
}

const ProductInfo = ({technicalData}: Props) => {
    const exchange = useExchange();


    useEffect(() => {
        if (exchange) {

            const p = technicalData.price.slice(1);

            console.log(p * exchange.usd);
            console.log(technicalData.price.slice(1), exchange);
        }
    }, [exchange]);

    if (!exchange) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx>
                    <h2>상품 정보</h2>

                    <S.ListBx>
                        <dl>
                            <dt>
                                <span>상품 이름</span>
                            </dt>
                            <dd>
                                {technicalData.title}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span>상세 정보</span>
                            </dt>
                            <dd>
                                <S.InfoBx>
                                    {
                                        technicalData.list.map((item: any, idx: number) => (
                                            <li key={`t-list-${idx}`}>
                                                {item.title}
                                                <span>{item.description}</span>
                                            </li>
                                        ))
                                    }
                                </S.InfoBx>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <span>상품 가격</span>
                            </dt>
                            <dd>
                                <S.ExchangeInfoBx>
                                    <ExchangeInfo/>
                                    <S.PriceContainer>
                                        <dl>
                                            <dt>
                                                {getComma(technicalData.price)}
                                            </dt>
                                            <dd>
                                                <S.PriceBx>
                                                    ₩{getComma(exchangeUsdToKwa(+technicalData.price.slice(1), exchange.usd))}
                                                </S.PriceBx>
                                            </dd>
                                        </dl>
                                    </S.PriceContainer>
                                </S.ExchangeInfoBx>
                            </dd>
                        </dl>
                    </S.ListBx>

                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default ProductInfo;