import * as S from "./styles";
import {PaymentFormListData, PaymentFormType} from "../../../../types/admin/payment-from";
import {useEffect, useState} from "react";
import {usePaymentFormContext} from "../../../../pages/admin/AdminPaymentFormPage";
import {formatNumberWithComma} from "../../../../lib/comma";
import {decimal} from "../../../../lib/math";

const getDefaultData = (): PaymentFormListData => {
    return {
        date: '',
        articles: '',
        qty: '',
        price: '',
        unit: '0',
    }
}

const PaymentForm = ({paymentFormData}: { paymentFormData: PaymentFormType }) => {
    const {exchange} = usePaymentFormContext()
    const [total, setTotal] = useState<number>(0);
    const {setPaymentForm} = usePaymentFormContext();
    const [listData, setListData] = useState<PaymentFormListData[]>(
        Array.from({length: 12}, () => getDefaultData())
    );

    const onChange = (value: string, idx: number, key: keyof PaymentFormListData) => {
        if (exchange) {
            const copyList: PaymentFormListData[] = listData.concat();
            copyList[idx][key] = value;

            const copyItem = copyList[idx];
            copyItem.unit = String(+copyItem.qty * +copyItem.price);
            
            setListData(copyList);
        }
    }

    useEffect(() => {
        if (exchange) {
            setPaymentForm(paymentFormData.type, listData);

            let sum = 0;
            listData.forEach((v) => {
                if (paymentFormData.unit === 'p') {
                    sum += (+v.qty * +v.price) * exchange.php;
                } else {
                    sum += (+v.qty * +v.price);
                }
            });

            setTotal(sum);
        }
    }, [listData, exchange]);

    return (
        <S.Inner>
            <S.TitleBx>
                {paymentFormData.title}
            </S.TitleBx>
            <S.TableBx>
                <thead>
                <tr>
                    <th>DATE</th>
                    <th>ARTICLES</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>
                        {
                            paymentFormData.unit === '$' ? 'U.S.D' : 'PESO'
                        }
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    listData.map((item, idx) => (
                        <tr key={`payment-form-${paymentFormData.type}-${idx}`}>
                            <td>
                                <input
                                    type="text"
                                    value={item.date}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'date')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={item.articles}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'articles')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={item.qty}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'qty')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={item.price}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'price')
                                    }
                                />
                            </td>
                            <td>
                                <span>
                                    {paymentFormData.unit === '$' ? '$' : '₱'}
                                </span>
                                <input
                                    type="text"
                                    style={{'textAlign': 'right'}}
                                    value={formatNumberWithComma(+decimal(item.unit))}
                                    disabled={true}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'unit')
                                    }
                                />
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </S.TableBx>
            <S.ListTotalBx>
                <S.ListTotal>
                    <span>
                        {paymentFormData.unit === '$' ? '$' : '₱'}
                    </span>
                    <input type="text" style={{'textAlign': 'right'}} disabled={true}
                           value={formatNumberWithComma(+decimal(total))}/>
                </S.ListTotal>
            </S.ListTotalBx>
        </S.Inner>
    )
}

export default PaymentForm;