import {DiverLessonAddContentType, DiverLessonPackageType, DiverLessonType} from "../../../types/recreation";

export const AdvancedData: DiverLessonType = {
    title: '이 과정에서는 ...',
    description: '한계수심 40M에 도전해보세요!\n여러종류의 특별한 다이빙(난파선 다이빙, 야간다이빙, 어류 식별코스등)의 해양실습이 이루어집니다.',
    infoList: [
        {
            type: 'TABLE',
            title: '과정 참가자격 및 준비물',
            infoType: [
                {
                    title: '참가자격',
                    description: '15세 (주니어 10 ~ 14세)',
                },
                {
                    title: '소요시간',
                    description: '4일',
                },
                {
                    title: '준비물',
                    description: '수영복, 개인세면도구, 증명사진 1부',
                },
                {
                    title: '비용',
                    description: '$600',
                }],
        },
        {
            type: 'ADD',
            title: '교육 절차',
            infoAdd:
                {
                    description: '필수다이빙 :\n- 야간다이빙\n- 딥다이빙\n- 네비게이션',
                    items: [
                        {
                            title: '다양한 스페셜 티 과정(선택)',
                            type: 'DEFAULT',
                        }]
                }
        }]
}

export const AdvancedDiverContentData: DiverLessonAddContentType = {
    title: '포함사항',
    infoList: [
        {
            title: '공항픽업',
            description: ''
        },
        {
            title: '숙박4박',
            description: '(2인 1실)'
        },
        {
            title: '조식/중식',
            description: ''
        },
        {
            title: '교육',
            description: ''
        },
        {
            title: '라이센스',
            description: ''
        },
        {
            title: '장비렌탈',
            description: ''
        },
        {
            title: '이론강의',
            description: ''
        },
        {
            title: '개방수역',
            description: '(10회 다이빙)'
        },
    ],
    referenceList: [
        {
            title: '숙소를 1인 단독 사용시에는 $35의 싱글차지가 붙습니다.',
        },
        {
            title: '아일랜드 펀다이빙 시 해양공원 입장료 & 씨푸드 바베큐 별도입니다.\n',
            linkInfo: {
                title: '입장료 안내',
                link: '/',
            }
        }
    ]
}

export const AdvancedPackageData: DiverLessonPackageType[] = [
    {
        title: 'NAUI 오픈워터 + 어드밴스드 패키지',
        description: '오픈워터 + 어드밴스드 동시에 등록 하시면 총 5일 과정 $800에 할인하여 진행 해드립니다!',
        price: '$800',
        list: [
            {
                title: '포함사항',
                description: '숙박 5일(2인 1실 사용조건), 공항픽업, 조식/중식, 교재, 장비렌탈',
            },
            {
                title: '미포함 사항',
                description: '아일랜드 펀다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
                linkInfo: {
                    title: '입장료 안내',
                    link: '/',
                }
            },
        ]
    },
    {
        title: '어드밴스드 + 나이트룩스 래키지',
        description: '어드밴스 + 나이트룩스 동시에 등록 하시면 총 3일 과정 $640에 할인하여 진행 해드립니다!',
        price: '$650',
        list: [
            {
                title: '포함 사항',
                description: '숙박 3일(2인 1실 사용조건), 공항픽업, 조식/중식, 교재, 장비렌탈',
                linkInfo: {
                    title: '입장료 안내',
                    link: '/',
                }
            },
            {
                title: '미포함 사항',
                description: '아일랜드 펀다이빙시 해양공원 입장료 & 씨푸드 바베큐 별도',
                linkInfo: {
                    title: '입장료 안내',
                    link: '/',
                }
            },
        ]
    }
]