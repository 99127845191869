import {useParams} from 'react-router-dom';
import {useExchange, usePaymentFormDetail} from '../../../api/exchange';
import * as S from './styles';
import moment from 'moment';
import {useCallback, useEffect, useState} from "react";
import {PaymentDetail} from "../../../types/admin/payment-from";
import {formatNumberWithComma} from "../../../lib/comma";
import {decimal} from "../../../lib/math";
import {exchangeUsdToPhp} from "../../../lib/exchange";
import ExchangeInfo from "../../common/exchange-info";
import UserInfoInput from "./common/user-info-input";
import PaymentBx from "./common/payment-bx";
import {useProcessPaymentAddItems, useProcessPaymentItems} from "../../../api/usePayment";
import {useAuthContext} from "../../auth-provider";
import PaymentDetailTable from "./payment-detail-table";

const categories = ['diving', 'equipment', 'meal', 'add'] as const;
const categoryTitles = {
    diving: '다이빙',
    equipment: '장비',
    meal: '식사',
    add: '추가사항',
};

type Category = (typeof categories)[number];

interface Props {
    username: string;
    email: string;
    email2: string;
    phone: string;
    license: string;
    ask: string;
    agree1: boolean;
    agree2: boolean;
}

const AdminPaymentFormDetail = () => {
    const {user, login, isLoading} = useAuthContext();
    const exchange = useExchange();
    const {id} = useParams();
    const paymentDetail = usePaymentFormDetail(id || "");

    const {mutate} = useProcessPaymentItems();
    const {mutate: mutateAdd} = useProcessPaymentAddItems()

    const [totalKWA, setKWA] = useState<number>(0);
    const [totalUSD, setUSD] = useState<number>(0);
    const [paymentValue, setPaymentValue] = useState<number>(0);

    const [isProcess, setIsProcess] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoading) {
            if (!user) login();
        }
    }, [user, isLoading]);

    const [info, setInfo] = useState<Props>({
        username: '',
        email: '',
        email2: '',
        phone: '',
        license: '',
        ask: '',
        agree1: false,
        agree2: false,
    });

    const onChange = useCallback(<K extends keyof Props>(value: string | boolean, key: K) => {
        const copyData = {
            ...info,
            [key]: value
        };

        setInfo(copyData);
    }, [info]);

    useEffect(() => {
        if (paymentDetail && paymentDetail.list) {
            categories.forEach((v) => {
                paymentDetail.list[v].forEach((item) => {
                    if (!item.isPayment) {
                        setIsProcess(true);
                    }
                });
            });
        }
    }, [paymentDetail]);

    useEffect(() => {
        if (exchange && paymentDetail) {
            let sum = 0;
            let paymentSum = 0;

            for (const [key, list] of Object.entries(paymentDetail.list)) {
                list.forEach((v: PaymentDetail) => {
                    const t = +v.qty * +v.price;
                    sum += t;

                    if (v.isPayment) paymentSum += t;
                })
            }

            setUSD(sum);
            setKWA(sum * exchange.usd);
            setPaymentValue(paymentSum * exchange.usd);
        }

    }, [paymentDetail, exchange]);

    const onClickPayment = useCallback((pg: string) => {
        if (window && paymentDetail && user) {
            let {
                username,
                email,
                email2,
                phone,
                ask,
                agree1,
                agree2,
            } = info;

            const amount = Math.ceil(+totalKWA - +paymentValue);
            const productName = 'Team max dive';

            if (paymentDetail.paymentInfo) {
                const {paymentInfo} = paymentDetail;
                const e = paymentInfo.email.split('@');

                username = paymentInfo.name;
                email = e[0];
                email2 = e[1];
                phone = paymentInfo.phone;
                ask = '추가 결제';
                agree1 = true;
                agree2 = true;
            }

            if (!username) {
                alert('이름을 작성해주세요.');
                return;
            }

            if (!email || !email2) {
                alert('이메일을 작성해주세요.');
                return;
            }

            if (!phone) {
                alert('휴대폰 번호를 작성해주세요.');
                return;
            }

            if (!ask) {
                alert('문의 사항을 작성해주세요.');
                return;
            }

            if (!agree1) {
                alert('관광 상품 판매 약관에 동의해주세요.');
                return;
            }

            if (!agree2) {
                alert('면책 약관에 동의해주세요');
                return;
            }

            const {IMP} = window as any;
            IMP.init('imp58214558');

            const data = {
                pg: pg,                                                 // PG사
                pay_method: 'card',                                     // 결제수단
                merchant_uid: `mid_${new Date().getTime()}`,            // 주문번호
                amount: amount,                                         // 결제금액
                name: productName,                                      // 주문명
                buyer_name: username,                                   // 구매자 이름
                buyer_tel: phone,                                       // 구매자 전화번호
                buyer_email: `${email}@${email2}`,                      // 구매자 이메일
                buyer_addr: '',                            // 구매자 주소
                buyer_postcode: '',                                // 구매자 우편번호
                m_redirect_url: 'https://teammaxdive.com/payment',
            }

            const callback = (response: any) => {
                if (paymentDetail.paymentInfo) {
                    mutateAdd({
                        sendData: {
                            technicalData: paymentDetail.list
                        }
                    });
                } else {
                    mutate({
                        sendData: {
                            userId: user.id,
                            masterId: id,
                            paymentData: info,
                            technicalData: paymentDetail.list
                        }
                    });

                    window.location.reload();
                }
            }

            IMP.request_pay(data, callback);
        }
    }, [user, info, paymentDetail, totalKWA, paymentValue, id]);

    if (!paymentDetail) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.TitleBx>
                    <h2>정산서 상세</h2>
                </S.TitleBx>

                <S.DetailBx>
                    <S.HeaderInfo>
                        <div className="team-name">
                            <span>팀 이름:</span> {paymentDetail.teamName || 'Default'}
                        </div>
                        <div className="date">
                            <span>작성일:</span> {moment(paymentDetail.createdAt).format('YYYY-MM-DD HH:mm')}
                        </div>
                    </S.HeaderInfo>
                    <S.PaymentContainer>
                        {categories.map((category: Category) => (
                            paymentDetail.list[category]?.length > 0 && (
                                <PaymentDetailTable
                                    key={`detail-table-${category}`}
                                    category={category}
                                    title={categoryTitles[category]}
                                    detail={paymentDetail.list[category]}
                                />
                            )
                        ))}
                    </S.PaymentContainer>

                    <S.TotalBx>
                        <ExchangeInfo/>
                        <S.TotalBxInner>
                            <div>
                                총 금액 : <span>$ {formatNumberWithComma(+decimal(+totalUSD))}</span>
                            </div>

                            <div>
                                총 금액 : <span>₩ {formatNumberWithComma(+decimal(+totalKWA))}</span>
                            </div>

                            <div>
                                지불 금액: <span>₩ {formatNumberWithComma(+decimal(+paymentValue))}</span>
                            </div>

                            <div>
                                남은 금액: <span>₩ {formatNumberWithComma(+decimal(+totalKWA - +paymentValue))}</span>
                            </div>
                        </S.TotalBxInner>
                    </S.TotalBx>

                    {
                        !paymentDetail.paymentInfo && <UserInfoInput onChange={onChange}/>
                    }

                    {
                        isProcess && <PaymentBx onClickPayment={onClickPayment}/>
                    }
                </S.DetailBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default AdminPaymentFormDetail;