import Payment from "../../components/payment";
import Layout from "../../components/layout";
import {useAuthContext} from "../../components/auth-provider";
import {useEffect} from "react";

const PaymentPage = () => {
    const {user, login, isLoading} = useAuthContext();

    useEffect(() => {
        if (!isLoading) {
            if (!user) login();
        }
    }, [user, isLoading]);

    if (!user) return null;

    return (
        <Layout>
            <Payment/>
        </Layout>
    )
}

export default PaymentPage;