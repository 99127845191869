import {getAxios, postAxios} from "./apiLink";
import {useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";

export const useProcessPayment = () => {
    const navigate = useNavigate();

    return useMutation({
        mutationFn: (sendData: any) => postAxios('/payment/process-payment', sendData),
        onSuccess: (result) => {
            const {paymentId} = result;
            navigate(`/payment-form-detail/${paymentId}`);
        }
    });
}

export const useProcessPaymentItems = () => {
    return useMutation({
        mutationFn: (sendData: any) => postAxios(`/payment/process-payment-items`, sendData),
        onSuccess: () => {
            alert("결제되었습니다.");
            window.location.reload();
        }
    })
}

export const useProcessPaymentAddItems = () => {
    return useMutation({
        mutationFn: (sendData: any) => postAxios(`/payment/process-payment-add-items`, sendData),
        onSuccess: () => {
            alert("추가 결제되었습니다.");
            window.location.reload();
        }
    });
}

export const usePaymentList = (userId: number | undefined) => {
    const {data} = useQuery({
        queryFn: () => getAxios('/payment/list', {
            userId
        }),
        queryKey: ['payment-list', userId],
        staleTime: 60 * 60 * 1000
    });

    return data;
}