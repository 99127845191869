import * as S from "./styles";
import MainPopupItem from "./main-popup-item";
import {usePopup} from "../../../api/popup";

const MainPopup = () => {
    const popupData = usePopup();

    if (!popupData) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <ul>
                    {
                        popupData.map((item, idx) => (
                            <MainPopupItem
                                popupKey={`t-max-key-${idx}`}
                                key={`main-popup-${idx}`}
                                data={item}
                            />
                        ))
                    }
                </ul>
            </S.Inner>
        </S.Wrap>
    )
}

export default MainPopup;