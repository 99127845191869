import {createContext, ReactNode, useContext} from "react";
import {useLogout, useUser} from "../../api/useUser";
import {useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

interface AutoContextType {
    user: any;
    login: () => void;
    logout: () => void;
    isLoading: boolean;
}

export const AuthContext = createContext<AutoContextType | null>(null);

interface Props {
    children: ReactNode
}

const AuthProvider = ({children}: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    let { user, isLoading } = useUser();

    const {mutate} = useLogout(() => {
        user = null;
    });

    const logout = () => {
        mutate();
    }

    const login = () => {
        navigate(`login?referer=${location.pathname}`)
    }

    return (
        <AuthContext.Provider value={{user, login, logout, isLoading}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = (): AutoContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuthContext Error");
    }
    return context;
};

export default AuthProvider;