import {getAxios, getAxios2, getAxiosDelete, postAxios} from "./apiLink";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {PaymentDetailList} from "../types/admin/payment-from";

export const useExchange = () => {
    const {data} = useQuery({
        queryFn: () => getAxios('/exchange'),
        queryKey: ['exchange'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const setCreatePaymentForm = async (teamName: string, sendData: any) => {
    return await postAxios("/exchange/paymentForm", {
        teamName,
        sendData: sendData
    });
}

export const addPaymentFormDetail = async (id: string, formData: {
    category: string;
    date: string;
    articles: string;
    qty: number;
    price: number;
}) => {
    return await postAxios(`/exchange/paymentForm/${id}/add`, formData);
}

export const usePaymentFormList = () => {
    const {data, isLoading} = useQuery({
        queryFn: () => getAxios('/exchange/paymentform/list'),
        queryKey: ['paymentFormList'],
        staleTime: 60 * 1000
    });

    return {
        data, isLoading
    }
}

export const usePaymentFormDetail = (id: string) => {
    const {data} = useQuery({
        queryFn: () => getAxios2<PaymentDetailList>(`/exchange/paymentForm/${id}`),
        queryKey: ['paymentFormDetail', id],
        staleTime: 60 * 1000
    });

    return data;
}

export const useAddPayment = (id?: number | string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({params, id}: { params: any, id: string }) => postAxios('/payment/add-item', {
            sendData: params,
            id
        }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["paymentFormDetail", String(id)]
            })
        }
    })
}

export const useDetailItemPayment = (id?: number | string) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (itemId: string) => getAxiosDelete("/payment/delete-item", {
            itemId
        }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["paymentFormDetail", String(id)]
            })
        }
    })
}
