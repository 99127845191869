import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getAxios2, patchAxios} from "./apiLink";
import {MainPopupType} from "../types/main";
import MainPopup from "../components/main/main-popup";

export const usePopup = () => {
    const {data} = useQuery({
        queryFn: () => getAxios2<MainPopupType[]>('/popup/list'),
        queryKey: ['main-popup'],
        staleTime: 60 * 60 * 1000
    });

    return data;
}

export const usePopupModify = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (sendData: MainPopupType) => patchAxios("/popup/modify", sendData),
        onSuccess: async () => {
            queryClient.invalidateQueries({
                queryKey: ['main-popup'],
            });

            alert("수정되었습니다.");
        }
    });
}