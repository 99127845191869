import * as S from "./styles";
import {PaymentFormListData, PaymentFormType} from "../../../../types/admin/payment-from";
import {useCallback, useEffect, useState} from "react";
import {usePaymentFormContext} from "../../../../pages/admin/AdminPaymentFormAddPage";
import {formatNumberWithComma} from "../../../../lib/comma";
import {decimal} from "../../../../lib/math";
import {useDetailItemPayment} from "../../../../api/exchange";
import {useParams} from "react-router-dom";

const getDefaultData = (): PaymentFormListData => {
    return {
        date: '',
        articles: '',
        qty: '',
        price: '',
        unit: '0',
    }
}

interface Props {
    paymentFormData: PaymentFormType,
    oldData?: any;
}

const PaymentForm = ({paymentFormData, oldData}: Props) => {
    const {id} = useParams();
    const {exchange} = usePaymentFormContext()
    const [total, setTotal] = useState<number>(0);
    const {setPaymentForm} = usePaymentFormContext();
    const [listData, setListData] = useState<PaymentFormListData[]>(
        Array.from({length: 12}, () => getDefaultData())
    );

    const {mutate} = useDetailItemPayment(id);

    useEffect(() => {
        let copyOldData = oldData.concat();
        copyOldData.forEach((v: PaymentFormListData) => {
            v.isOld = '1';
        });

        let copylistData = listData.concat();
        copylistData.splice(0, oldData.length, ...oldData);

        setListData(copylistData);
    }, [oldData]);

    const onChange = (value: string, idx: number, key: keyof PaymentFormListData) => {
        if (exchange) {
            const copyList: PaymentFormListData[] = listData.concat();
            copyList[idx][key] = value;

            const copyItem = copyList[idx];
            copyItem.unit = String(+copyItem.qty * +copyItem.price);

            setListData(copyList);
        }
    }

    useEffect(() => {
        if (exchange) {
            setPaymentForm(paymentFormData.type, listData);

            let sum = 0;
            listData.forEach((v) => {
                if (v.isPayment !== '1') {
                    if (paymentFormData.unit === 'p') {
                        sum += (+v.qty * +v.price) * exchange.php;
                    } else {
                        sum += (+v.qty * +v.price);
                    }
                }
            });

            setTotal(sum);
        }
    }, [listData, exchange]);

    const onClickDelete = useCallback((itemId: string | undefined) => {
        if (itemId) {
            mutate(itemId);
            window.location.reload();
        }
    }, []);

    return (
        <S.Inner>
            <S.TitleBx>
                {paymentFormData.title}
            </S.TitleBx>
            <S.TableBx>
                <thead>
                <tr>
                    <th>DATE</th>
                    <th>ARTICLES</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>
                        {
                            paymentFormData.unit === '$' ? 'U.S.D' : 'PESO'
                        }
                    </th>
                    <th>상태</th>
                </tr>
                </thead>
                <tbody>
                {
                    listData.map((item, idx) => (
                        <tr
                            key={`payment-form-${paymentFormData.type}-${idx}`}
                            style={{
                                'backgroundColor': item.isPayment === '1' ? 'rgba(255, 255, 0, 0.5)' : item.isOld === '1' ? 'rgba( 0, 0, 0, 0.05 )' : ''
                            }}
                        >
                            <td>
                                <input
                                    type="text"
                                    disabled={item.isPayment === '1' || item.isOld === '1'}
                                    value={item.date}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'date')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    disabled={item.isPayment === '1' || item.isOld === '1'}
                                    value={item.articles}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'articles')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    disabled={item.isPayment === '1' || item.isOld === '1'}
                                    value={item.qty}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'qty')
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    disabled={item.isPayment === '1' || item.isOld === '1'}
                                    value={item.price}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'price')
                                    }
                                />
                            </td>
                            <td>
                                <span>
                                    {paymentFormData.unit === '$' ? '$' : '₱'}
                                </span>
                                <input
                                    type="text"
                                    style={{'textAlign': 'right'}}
                                    value={formatNumberWithComma(+decimal(item.unit))}
                                    disabled={true}
                                    onChange={(e) =>
                                        onChange(e.target.value, idx, 'unit')
                                    }
                                />
                            </td>
                            <td>
                                {item.isOld === '1' ? (
                                    <div>
                                        {
                                            item.isPayment === "1" ? (
                                                <div>결제</div>
                                            ) : (
                                                <S.BtnDelete onClick={() => onClickDelete(item.id)}>
                                                    삭제
                                                </S.BtnDelete>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div>

                                    </div>
                                )}
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </S.TableBx>
            <S.ListTotalBx>
                <S.ListTotal>
                    <span>
                        {paymentFormData.unit === '$' ? '$' : '₱'}
                    </span>
                    <input type="text" style={{'textAlign': 'right'}} disabled={true}
                           value={formatNumberWithComma(+decimal(total))}/>
                </S.ListTotal>
            </S.ListTotalBx>
        </S.Inner>
    )
}

export default PaymentForm;