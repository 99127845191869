import {usePopup} from "../../../api/popup";
import MyPopupItem from "./MyPopupItem";

const MyPopup = () => {
    const data = usePopup();

    if (!data) return null;

    return (
        <div>
            {
                data.map((item, idx) => (
                    <MyPopupItem
                        idx={idx}
                        key={`admin-popup-${idx}`}
                        data={item}
                    />
                ))
            }

        </div>
    )
}

export default MyPopup;