import styled from "styled-components";

export const Wrap = styled.div`
    max-width: 500px;
    width: 100%;

    margin-bottom: 50px;
    border-bottom: 1px solid #000000;
    padding-bottom: 50px;

    &:last-child {
        border-bottom: 0;
    }
`

export const Inner = styled.div`
    width: 100%;

    h2 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
`

export const InputText = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 10px;

    label {
        width: 70px;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 700;
    }

    input, textarea {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        padding: 5px;
        box-sizing: border-box;
    }

    textarea {
        height: 100px;
    }
`

export const InputCheck = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    label {
        width: 70px;
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 700;
    }
`