import {ContentType} from "../../../types/my";
import MyNews from "../MyNews";
import MyTeammaxStory from "../MyTeammaxStory";
import {useAuthContext} from "../../auth-provider";
import MyNotice from "../MyNotice";
import MyPayment from "../MyPayment";
import MyPopup from "../MyPopup";

const MyContents = ({contentType}: { contentType: keyof ContentType }) => {
    const {user} = useAuthContext();
    return (
        <>
            {
                contentType === "notice" && <MyNotice userId={user.id}/>
            }
            {
                contentType === "news" && <MyNews/>
            }
            {
                contentType === "story" && <MyTeammaxStory/>
            }
            {
                contentType === "payment" && <MyPayment userId={user.id}/>
            }
            {
                contentType === "popup" && <MyPopup/>
            }
        </>
    )
}

export default MyContents