import Layout from "../../../components/layout";
import OpenwaterAdvanced2 from "../../../components/recreation/openwater-advanced2";

const OpenWaterAdvencedPage2 = () => {
    return (
        <Layout>
            <OpenwaterAdvanced2/>
        </Layout>
    )
}

export default OpenWaterAdvencedPage2;