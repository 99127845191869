import * as S from "./styles";
import {useCallback, useEffect, useState} from "react";
import {MainPopupType} from "../../../../types/main";

interface Props {
    popupKey: string;
    data: MainPopupType;
}

const MainPopupItem = ({popupKey, data}: Props) => {
    const [isView, setView] = useState<boolean>(true);

    useEffect(() => {
        const storedDate = localStorage.getItem(popupKey);
        const today = new Date().toISOString().split("T")[0];

        if (storedDate === today) {
            setView(false);
        }
    }, []);

    const onClickHide = useCallback(() => {
        const today = new Date().toISOString().split("T")[0];
        localStorage.setItem(popupKey, today);
        setView(false);
    }, []);

    if (!isView) return null;
    if (data.hidden) return null;

    return (
        <S.PopupItem
            initial={{y: 15, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{
                duration: 0.5,
                ease: 'easeOut',
            }}
        >
            <a href={data.link} target="_blank">
                <img src={data.img} alt=""/>
                <S.InfoBx>
                    <dl>
                        <dt>{data.title}</dt>
                        <dd>{data.description}</dd>
                    </dl>
                </S.InfoBx>
            </a>
            <S.CloseBx>
                <button onClick={onClickHide}>오늘 하루 안보기</button>
                <button onClick={() => setView(false)}>닫기</button>
            </S.CloseBx>
        </S.PopupItem>
    )
}

export default MainPopupItem;