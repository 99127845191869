import * as S from "./styles";
import PaymentForm from "./payment-form";
import {DivingData, EquipmentData, MealData, AddData} from "./data";
import PaymentTotal from "./payment-total";
import PaymentUnit from "./payment-unit";
import BtnDefault from "../../common/btn-default";
import {usePaymentFormContext} from "../../../pages/admin/AdminPaymentFormAddPage";
import {useParams} from "react-router-dom";
import {usePaymentFormDetail} from "../../../api/exchange";
import {useEffect} from "react";

const AdminPaymentFormAdd = () => {
    const {id} = useParams();
    const {onClickCreate, teamName, setTeamName} = usePaymentFormContext();
    const data = usePaymentFormDetail(id || '');

    useEffect(() => {
        if (data) setTeamName(data.teamName);
    }, [data]);

    if (!data) return null;

    return (
        <S.Wrap>
            <S.Inner>
                <S.TeamNameBx>
                    <h2>팀 이름</h2>
                    <input
                        type="text"
                        value={teamName}
                        disabled={!!data}
                        onChange={(e) => setTeamName(e.target.value)}
                    />
                </S.TeamNameBx>

                <S.TitleBx>정산서</S.TitleBx>

                <S.FormBx>
                    <PaymentForm paymentFormData={DivingData} oldData={data.list.diving}/>
                    <PaymentForm paymentFormData={EquipmentData} oldData={data.list.equipment}/>
                </S.FormBx>
                <S.FormBx>
                    <PaymentForm paymentFormData={MealData} oldData={data.list.meal}/>
                    <PaymentForm paymentFormData={AddData} oldData={data.list.add}/>
                </S.FormBx>

                <S.TotalBx>
                    <PaymentTotal/>
                    <PaymentUnit/>
                </S.TotalBx>

                <S.BtnBx>
                    <BtnDefault title={'정산서 생성하기'} onClick={onClickCreate}></BtnDefault>
                </S.BtnBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default AdminPaymentFormAdd;