import * as S from "./styles";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {CalculatorListType, CalculatorProcess} from "../../../../../../types/calculator";
import {useCalculatorContext} from "../../index";
import {GetTechnicalOptions} from "../../../../../../api/calculator";

const CheckBx = ({children}: { children: ReactNode }) => {
    return (
        <S.CheckBx
            initial={{x: -20, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            transition={{
                duration: 0.2,
                ease: 'easeOut',
            }}
        >
            {children}
        </S.CheckBx>
    )
}

const PackageList = ({calculatorList, packageName}: {
    calculatorList: CalculatorListType[],
    packageName: 'funDivingList' | 'technicalList'
}) => {
    const {setSchedule} = useCalculatorContext();

    const [option, setOption] = useState<CalculatorListType>();
    const [optionIdx, setOptionIdx] = useState<number>(-1);

    const [depth2, setDepth2] = useState<boolean[]>(
        Array.from({length: calculatorList.length}, () => false)
    );

    const [depth3, setDepth3] = useState<boolean[]>([]);
    const [depth4, setDepth4] = useState<boolean[]>([]);

    const [technicalOptions, setTechnicalOptions] = useState<any>();

    const [isTechnicalOptionsView, setTechnicalOptionsView] = useState<boolean>(false);

    const getTechnicalOptions = async () => {
        const result = await GetTechnicalOptions();
        setTechnicalOptions(result);
    }

    useEffect(() => {
        if (packageName === 'technicalList') {
            getTechnicalOptions();
        }
    }, [packageName]);

    const onChangeDepth2 = useCallback((idx: number) => {
        const copyDepth2 = depth2.concat().map((_) => false);
        copyDepth2[idx] = true;

        setDepth2(copyDepth2);

        setOption(calculatorList[idx]);
        setOptionIdx(idx);

        const depth3Arr: boolean[] = [];
        calculatorList[idx].optionList?.forEach((v, idx) => {
            depth3Arr[idx] = v.default;
        });

        setDepth3(depth3Arr);
        setTechnicalOptionsView(false);
    }, [depth2]);

    const onChangeDepth3 = useCallback((idx: number) => {
        const depth3Arr = depth3.concat();
        depth3Arr[idx] = !depth3Arr[idx];

        setDepth3(depth3Arr);

        const isView = depth3Arr.includes(true);
        setTechnicalOptionsView(isView);
    }, [depth3]);

    const onChangeDepth4 = useCallback((idx: number) => {
        const depth4Arr = depth4.concat();
        depth4Arr[idx] = !depth4Arr[idx];

        setDepth4(depth4Arr);
    }, [depth4]);

    useEffect(() => {
        if (option) {
            const isDepth2 = depth2.includes(true);
            const isDepth3 = depth3.includes(true);

            if (isDepth2 || isDepth3) {
                let data: CalculatorProcess = {
                    name: packageName,
                    process: {
                        id: 0,
                        name: '',
                        price: 0,
                    },
                    processList: [],
                    technicalOptions: [],
                }

                depth2.forEach((v) => {
                    if (v) {
                        data.process = {
                            id: option.packageListId,
                            name: option.name,
                            price: option.price || 0,
                        }

                        option.optionList?.forEach((option: any, sIdx: number) => {
                            if (option.default || depth3[sIdx]) {
                                data.processList.push(option);
                            }
                        })
                    }
                });

                depth4.forEach((v, idx) => {
                    if (v) {
                        data.technicalOptions?.push(technicalOptions[idx]);
                    }
                })

                setSchedule(data, packageName);
            }
        }
    }, [depth2, depth3, depth4, option, technicalOptions]);


    return (
        <S.ListBx>
            {
                calculatorList && <S.Bx>
                    <ul>
                        {
                            calculatorList.map((item, idx) => (
                                <CheckBx key={`category1-list-${packageName}-${idx}`}>
                                    <input
                                        id={`package-list-${packageName}-${idx}`}
                                        type={'radio'}
                                        name={packageName}
                                        onChange={() => onChangeDepth2(idx)}
                                    />

                                    <label htmlFor={`package-list-${packageName}-${idx}`}>
                                        <div>
                                            {item.name}
                                            {
                                                item.price && <span>${item.price}</span>
                                            }
                                        </div>

                                        {
                                            item.optionList && <img
                                                src={optionIdx === idx ? "/icon/ic_arrow_down_red.svg" : "/icon/ic_arrow_down.svg"}
                                                alt=""
                                            />
                                        }
                                    </label>
                                </CheckBx>
                            ))
                        }
                    </ul>
                </S.Bx>
            }

            {
                option && option.optionList && <S.Bx>
                    <ul>
                        {
                            option.optionList.map((item, idx) => (
                                <CheckBx key={`option-${packageName}-${optionIdx}-${idx}`}>
                                    <input
                                        id={`option-${packageName}-${optionIdx}-${idx}`}
                                        type={'checkbox'}
                                        defaultChecked={item.default}
                                        disabled={item.default}
                                        onChange={() => onChangeDepth3(idx)}
                                    />

                                    <label htmlFor={`option-${packageName}-${optionIdx}-${idx}`}>
                                        <div>
                                            {item.name}
                                            <span>${item.price}</span>
                                        </div>

                                        {
                                            packageName === 'technicalList' && <img
                                                src={optionIdx === idx ? "/icon/ic_arrow_down_red.svg" : "/icon/ic_arrow_down.svg"}
                                                alt=""
                                            />
                                        }
                                    </label>
                                </CheckBx>
                            ))
                        }
                    </ul>
                </S.Bx>
            }

            {
                packageName === 'technicalList' && isTechnicalOptionsView && technicalOptions && technicalOptions.length > 0 &&
                <S.Bx>
                    <ul>
                        {
                            technicalOptions.map((item: any, idx: number) => (
                                <CheckBx key={`technical-option-${packageName}-${optionIdx}-${idx}`}>
                                    <input
                                        id={`technical-option-${packageName}-${optionIdx}-${idx}`}
                                        type={'checkbox'}
                                        onChange={() => onChangeDepth4(idx)}
                                    />

                                    <label htmlFor={`technical-option-${packageName}-${optionIdx}-${idx}`}>
                                        <div>
                                            {item.name}
                                            <span>${item.price}</span>
                                        </div>
                                    </label>
                                </CheckBx>
                            ))
                        }
                    </ul>
                </S.Bx>
            }
        </S.ListBx>
    )
}

export default PackageList;