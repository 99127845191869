import * as S from "../styles";
import {usePaymentContext} from "../../index";
import {useCallback, useState} from "react";
import {usePopupContext} from "../../../popup/PopupProvider";
import {agree1} from "../data";
import Agree from "../agree";

interface Props {
    username: string;
    email: string;
    email2: string;
    phone: string;
    license: string;
    ask: string;
    agree1: boolean;
    agree2: boolean;
}

const UserInfoInput = () => {
    const {showMessage} = usePopupContext();
    const {setInfoData} = usePaymentContext();

    const [info, setInfo] = useState<Props>({
        username: '',
        email: '',
        email2: '',
        phone: '',
        license: '',
        ask: '',
        agree1: false,
        agree2: false,
    });

    const onChange = useCallback(<K extends keyof Props>(value: string | boolean, key: K) => {
        const copyData = {
            ...info,
            [key]: value
        };

        setInfo(copyData);
        setInfoData(copyData);
    }, [info]);

    return (
        <S.Wrap>
            <S.Inner>
                <S.ContentBx>
                    <h2>결제자 정보</h2>

                    <S.ListBx>
                        <dl>
                            <dt><span>이름</span></dt>
                            <dd><input type="text" placeholder={'홍길동'}
                                       onChange={(e) => onChange(e.target.value, 'username')}/></dd>
                        </dl>
                        <dl>
                            <dt><span>이메일</span></dt>
                            <dd>
                                <input type="text" placeholder={'email'}
                                       onChange={(e) => onChange(e.target.value, 'email')}/>
                                <span style={{'color': '#aaaaaa', 'fontWeight': '600'}}>@</span>
                                <input type="text" placeholder={'.com'}
                                       onChange={(e) => onChange(e.target.value, 'email2')}/>
                            </dd>
                        </dl>
                        <dl>
                            <dt><span>휴대폰 번호</span></dt>
                            <dd>
                                <input type="text" placeholder={'- 없이 입력'}
                                       onChange={(e) => onChange(e.target.value, 'phone')}/>
                            </dd>
                        </dl>

                        <dl>
                            <dt><span>보유 라이센스</span></dt>
                            <dd>
                                <input type="text" placeholder={'라이센스 미보유 시 빈칸제출'}
                                       onChange={(e) => onChange(e.target.value, 'license')}/>
                            </dd>
                        </dl>
                        <dl>
                            <dt><span>문의 사항</span></dt>

                            <dd>
                                <textarea placeholder={'희망 날짜와 문의사항 적어주세요'}
                                          onChange={(e) => onChange(e.target.value, 'ask')}></textarea>
                            </dd>
                        </dl>
                        <dl>
                            <dt><span>이용 약관</span></dt>
                            <dd>
                                <S.CheckContainer>
                                    <S.CheckBx>
                                        <input id="ch1" type="checkbox"
                                               onChange={(e) => onChange(e.target.checked, 'agree1')}/>
                                        <label htmlFor="ch1"><i></i></label>
                                        <button onClick={() => {
                                            showMessage(
                                                <Agree title={'관공 상품 판매 약관'} content={agree1}/>
                                            )
                                        }}>
                                            관광 상품 판매 약관
                                            <img src="/icon/ic_arrow_right.svg" alt=""/>
                                        </button>
                                    </S.CheckBx>
                                    <S.CheckBx>
                                        <input id="ch2" type="checkbox"
                                               onChange={(e) => onChange(e.target.checked, 'agree2')}/>
                                        <label htmlFor="ch2"><i></i></label>
                                        <button onClick={() => {
                                            showMessage(
                                                <Agree title={'면책 동의 약관'} content={agree1}/>
                                            );
                                        }}>
                                            면책 동의 약관
                                            <img src="/icon/ic_arrow_right.svg" alt=""/>
                                        </button>
                                    </S.CheckBx>
                                </S.CheckContainer>
                            </dd>
                        </dl>
                    </S.ListBx>
                </S.ContentBx>
            </S.Inner>
        </S.Wrap>
    )
}

export default UserInfoInput;