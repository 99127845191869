import {motion} from "framer-motion";
import * as S from "./styles";
import {useEffect, useRef} from "react";
import {gsap, Power0} from "gsap";
import MainPopup from "./main-popup";

interface Props {
    title: string;
    description?: string;
    imgSrc: string[];
}

const Visual = ({title, description, imgSrc}: Props) => {
    const ImgBxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let timer: NodeJS.Timer;
        if (ImgBxRef.current) {
            const imgBx = ImgBxRef.current as HTMLDivElement;
            const imgs = imgBx.querySelectorAll('img');
            let count = 0;

            if( imgs.length > 1 ){
                timer = setInterval(() => {
                    count = (count + 1) % imgs.length;
                    imgs.forEach((item, idx) => {
                        if (idx === count) {
                            gsap.to( item, { opacity: 1, duration: 1, ease: Power0.easeInOut });
                        } else {
                            gsap.to( item, { opacity: 0, duration: 1, ease: Power0.easeInOut });
                        }
                    });

                }, 3000);
            }
        }

        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <S.Wrap>
            <MainPopup />
            <motion.dl
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{
                    duration: 0.5,
                    ease: 'easeOut',
                }}
            >
                <dt>{title}</dt>
                {
                    description && <dd>{description}</dd>
                }
            </motion.dl>
            <S.ImgBx
                ref={ImgBxRef}
                initial={{scale: 1}}
                animate={{scale: 1.2}}
                transition={{
                    duration: 30,
                    ease: 'linear',
                    repeat: Infinity,
                    repeatType: 'reverse',
                }}
            >
                {
                    imgSrc.map((item, idx) => (
                        <motion.img key={`img-visual-${idx}`}
                                    src={item} alt=""
                        />
                    ))
                }

            </S.ImgBx>
        </S.Wrap>
    )
}

export default Visual;