import styled from "styled-components";
import media from "../../lib/media";
import {motion} from "framer-motion";

export const Wrap = styled.div`
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    > dl {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 160px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        dt {
            font-family: Poppins sans-serif;
            font-weight: 700;
            font-size: 48px;
            line-height: 57px;
            color: #ffffff;
            word-break: keep-all;
            text-align: center;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        }

        dd {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            white-space: pre-wrap;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
        }
    }

    ${media.tablet`
        dl{
            padding: 0 20px;
            box-sizing: border-box;
            top: 100px;
            dt{
                max-width: 580px;
                width: 100%;
            }
        }
    `};

    ${media.mobile`
        height: 420px;
        overflow: hidden;
        dl{
            top: 48px;
            gap: 12px;
            dt{
                font-size: 20px;
                line-height: 30px;
            }
            
            dd{
                font-size: 14px;
                line-height: 19px;
            }
        }
    `};
`

export const ImgBx = styled(motion.div)`
    position: relative;
    background-color: #000000;

    width: 100%;
    height: 840px;
    
    img {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        &:first-child{
            position: relative;
            z-index: 10;
            opacity: 1;
        }
    }

    ${media.mobile`
        img{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 834px;
            height: 420px;
        }
    `};
`
