import * as S from "./styles";
import moment from "moment";
import BtnDefault from "../../../common/btn-default";
import {useState} from "react";

const PaymentListItem = ({item}: { item: any }) => {
    const [isBtn, setBtn] = useState<boolean>(false);

    return (
        <S.ListItem
            onMouseLeave={() => setBtn(false)}
            onMouseEnter={() => setBtn(true)}
            onTouchEnd={() => setBtn(true)}
        >
            {
                isBtn && <S.BtnBx>
                    <BtnDefault title={`확인`} link={`/payment-form-detail/${item.id}`}></BtnDefault>
                    <BtnDefault title={`항목 추가`} link={`/admin/payment-form/${item.id}`}></BtnDefault>
                </S.BtnBx>
            }

            <S.ListContent>
                <dl>
                    <dt>
                        제목 : {item.team_name || 'Default'}
                    </dt>
                    <dd>
                        {moment(item.created_at).format('YYYY-MM-DD HH:mm')}
                    </dd>
                </dl>
            </S.ListContent>

            <S.AmountBx>
                <dl>
                    <dt>총 금액:</dt>
                    <dd>${item.total_amount}</dd>
                </dl>

                <dl>
                    <dt>지불 금액:</dt>
                    <dd>${item.paid_amount}</dd>
                </dl>
            </S.AmountBx>
        </S.ListItem>

    )
}

export default PaymentListItem;