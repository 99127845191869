import {useCallback} from "react";
import * as S from "../styles";
import {usePaymentContext} from "../../index";
import styled from "styled-components";
import {useExchange} from "../../../../api/exchange";
import {useProcessPayment} from "../../../../api/usePayment";
import {useAuthContext} from "../../../auth-provider";

const Wrap = styled.div`
`

const Inner = styled.div`
`

interface Props {
    technicalData: any;
}

const PaymentBx = ({technicalData}: Props) => {
    const { user } = useAuthContext();
    const {paymentData} = usePaymentContext();
    const exchange = useExchange();
    const {mutate} = useProcessPayment();

    const onClick = useCallback((pg: string) => {
        if (window && exchange && user) {
            const {
                username,
                email,
                email2,
                phone,
                ask,
                agree1,
                agree2,
            } = paymentData;

            const amount = +technicalData.price.substring(1) * exchange.usd;
            const productName = technicalData.title;

            if (!username) {
                alert('이름을 작성해주세요.');
                return;
            }

            if (!email || !email2) {
                alert('이메일을 작성해주세요.');
                return;
            }

            if (!phone) {
                alert('휴대폰 번호를 작성해주세요.');
                return;
            }

            if (!ask) {
                alert('문의 사항을 작성해주세요.');
                return;
            }

            if (!agree1) {
                alert('관광 상품 판매 약관에 동의해주세요.');
                return;
            }

            if (!agree2) {
                alert('면책 약관에 동의해주세요');
                return;
            }

            console.log("===== 결제 시도 ========");
            console.log(paymentData);

            const {IMP} = window as any;
            // IMP.init('imp14305517');
            IMP.init('imp58214558');

            const data = {
                pg: pg,                                                 // PG사
                pay_method: 'card',                                     // 결제수단
                merchant_uid: `mid_${new Date().getTime()}`,            // 주문번호
                amount: amount,                                         // 결제금액
                name: productName,                                      // 주문명
                buyer_name: username,                                   // 구매자 이름
                buyer_tel: phone,                                       // 구매자 전화번호
                buyer_email: `${email}@${email2}`,                      // 구매자 이메일
                buyer_addr: '',                            // 구매자 주소
                buyer_postcode: '',                                // 구매자 우편번호
                m_redirect_url: 'https://teammaxdive.com/payment',
            }

            const callback = (response: any) => {
                mutate({
                    sendData: {
                        userId: user.id,
                        paymentData, technicalData
                    }
                });
            }

            IMP.request_pay(data, callback);
        }
    }, [user, paymentData, exchange]);

    if (!exchange) return null;

    return (
        <Wrap>
            <Inner>
                <S.ContentBx>
                    <h2>결제 수단</h2>
                    <S.PaymentListBx>
                        <button onClick={() => onClick('kakaopay')}>
                            <img src="/image/btn_pay_kakao.png" alt=""/>
                        </button>
                        <button onClick={() => onClick('tosspay')}>
                            <img src="/image/btn_pay_toss.png" alt=""/>
                        </button>
                        <button onClick={() => onClick('nice_v2')}>
                            다른 결제 수단 선택
                        </button>
                    </S.PaymentListBx>
                </S.ContentBx>

            </Inner>
        </Wrap>
    )
}

export default PaymentBx;